import React from "react";
import styles from "../Stage/Stage.module.scss";

const Stage = (props) => {

    const Triangle = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 200" className={styles.Stage__Triangle}>
                <polygon className={styles.Stage__Triangle__Color} points="1600 200 1600 0 0 200 1600 200"/>
            </svg>
        )
    }

    const StageImage = props.StageImage
    const StageImageMobile = props.StageImageMobile
    const StageImageAlt = props.StageImageAlt
    const StageHeadlinePosition = props.StageHeadlinePosition
    const StageHeadline = props.StageHeadline
    const StageHeadlineAlt = props.StageHeadlineAlt

    const classesStageImage = `${styles.Stage__Image} ${StageHeadlinePosition}`
    const classesHeadline = `${styles.Stage__Headline} ${StageHeadlinePosition}`

    return (
        <div className={styles.Stage}>
            < div className={classesStageImage}>
                <img className={styles.Stage__Image__Desktop} src={StageImage} alt={StageImageAlt} />
                <img className={styles.Stage__Image__Mobile} src={StageImageMobile} alt={StageImageAlt} />
                <Triangle />
            </div>
            <div className={classesHeadline}>
                <img src={StageHeadline} alt={StageHeadlineAlt} />
            </div>
        </div>
    );
}

export default Stage;