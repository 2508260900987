import React from "react";
import styles from "../Text/Text.module.scss";
import PropTypes from 'prop-types';

const Text = (props) => {
    const classes = props.white ? `${styles.Text} ${styles.Text__White}` : props.blue ? `${styles.Text} ${styles.Text__Ultramarin}` : `${styles.Text} ${styles.Text__Ultramarin}`
    return (
        <p className={classes}>{props.children}</p>
    );
}

Text.propTypes = {
    blue: PropTypes.bool,
    white: PropTypes.bool
}

export default Text;