import React, { useContext } from "react"
import { StateContext } from "../../providers/StateProvider"
import { useEffect } from "react"
import styles from "../Intro/Intro.module.scss"
import content from "../../content/content.json"
import Button from "../Button"
import Stage from "../Stage"
import Text from "../Text"
import StageImage from "../../images/stages/Intro_Stage.jpg" 
import StageImageMobile from "../../images/stages/Intro_Stage_m.jpg"
import StageHeadline from "../../images/einstellungschecker.svg"

const Intro = () => {
    
	const { state, actions } = useContext(StateContext)

	useEffect(() => {
		setTimeout(function () {
			window.scrollTo(0, 0)
        },250);

        // Initialisierung der Fragen
        if (state.qList.length  === 0) {
            //console.log("> init")
            let temp = []
            for (let i = 0; i < content.questions.length; i++) {
                temp.push(i);
            }
            //console.log("_ temp:")
            //console.log(temp)
            let randomizer = []
            for (let i = 0; i < content.question_count; i++) {
                let random = Math.floor(Math.random()*temp.length)
                randomizer.push(temp[random])
                temp.splice(random, 1)
            }
            //console.log("_ randomizer:")
            //console.log(randomizer)
            let newQList = []
            for (let i = 0; i < randomizer.length; i++) {
                newQList.push(content.questions[randomizer[i]])
            }
            //console.log("_ newQList:")
            //console.log(newQList)
            actions.setAnswerList([])
            actions.setList(newQList)
        }
        
	})

    return (
        <section className={styles.Intro}>
            <Stage
                StageImage={StageImage}
                StageImageMobile={StageImageMobile}
                StageImageAlt={""}
                StageHeadlinePosition={"intro"}
                StageHeadline={StageHeadline}
                StageHeadlineAlt={""}
            />
            <article className={styles.Intro__Copy}>
                <Text>{content.pages.intro.copy}</Text>
                <Button primary={true} title={content.pages.intro.cta_label} target={"/frage1"} />
            </article>
        </section>
    );
}

export default Intro;