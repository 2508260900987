import React from "react"
import Layout from "../layouts/Base"
import Intro from "../components/Intro"
import "../style/main.scss"

export default function Home() {

	return (
		<Layout>
			<Intro />
		</Layout>
	)
}
