import React from "react"
import { navigate } from "gatsby"
import styles from "../Button/Button.module.scss"
import PropTypes from 'prop-types';

const IconArrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 22">
            <polygon className="cta_arrow" points="0,0 15,11 0,22 "/>
        </svg>
    )
}

const Button = (props) => {
    const classes = props.primary ? `${styles.Button} ${styles.Button__Primary}` : props.secondary ? `${styles.Button} ${styles.Button__Secondary}` : `${styles.Button} ${styles.Button__Primary}`
    
    const GoTo = () => {
        if (props.external) {
            window.open(props.target, "_blank")
        }
        else {
            navigate(props.target)
        }
    }

    return (
        <button className={classes} onClick={()=>GoTo()}>
            {props.title}<IconArrow/>
        </button>
    );
}

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  external: PropTypes.bool
}

export default Button;